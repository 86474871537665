import Link from "next/link";

const TextButton = ({
  type = "link",
  bg = false,
  fullW = false,
  handler,
  children,
  locale,
  href,
}) => {
  let classes;

  if (bg) {
    classes = `
          text-neutral-800 bg-gray-100 hover:bg-gray-200 py-2 px-6 rounded-md hover:bg-opacity-80 transition-all duration-100 ease-in text-center whitespace-nowrap ${
            fullW ? "w-full" : null
          }
        `;
  } else {
    classes =
      "text-neutral-800 px-6 py-2 whitespace-nowrap hover:bg-gray-100 rounded-md transition-all duration-100 ease-in";
  }

  if (type === "button") {
    return (
      <button onClick={handler} className={classes}>
        {children}
      </button>
    );
  } else {
    return (
      <Link href={href} locale={locale} className={classes}>
        {children}
      </Link>
    );
  }
};

export default TextButton;
