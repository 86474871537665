import { Envelope } from "akar-icons";
import Link from "next/link";

const PrimaryBlackButton = ({
  type = "button",
  href,
  fullW = false,
  icon = null,
  handler,
  size = "small",
  loading = false,
  children,
  disabled = false,
}) => {
  if (type === "button") {
    return (
      <button
        onClick={handler}
        disabled={loading || disabled}
        className={`js-primary-product
          bg-neutral-800 flex justify-center items-center gap-4 px-6 rounded-md text-gray-50 hover:bg-opacity-80 transition-all duration-100 ease-in text-center whitespace-nowrap ${
            fullW ? "w-full" : null
          } ${size === "large" ? "py-3" : "py-2"}
        `}
      >
        {icon === "mail" ? <Envelope size={20} /> : null}
        {loading ? (
          <svg
            className="w-6 h-6 animate-spin text-primaryLight"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
        ) : (
          children
        )}
      </button>
    );
  } else {
    return (
      <Link
        href={href}
        className={`
          bg-neutral-800 px-6 rounded-md flex items-center justify-center text-gray-50 hover:bg-opacity-80 transition-all duration-100 ease-in text-center whitespace-nowrap ${
            fullW ? "w-full" : null
          } ${size === "large" ? "py-3" : "py-2"}
        `}
      >
        {children}
      </Link>
    );
  }
};

export default PrimaryBlackButton;
