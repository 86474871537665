import { deleteConfirmationPopupAtom } from "../../../atoms/popupAtoms";
import errorToast from "../../../libs/errorToast";
import getTranslatedText from "../../../libs/getTranslatedText";
import PrimaryBlackButton from "../buttons/PrimaryBlackButton";
import TextButton from "../buttons/TextButton";
import { CircleXFill } from "akar-icons";
import axios from "axios";
import { atom, useAtom } from "jotai";
import { useRouter } from "next/dist/client/router";
import { useEffect } from "react";
import ReactDOM from "react-dom";
import toast from "react-hot-toast";
import { mutate } from "swr";

const mountedAtom = atom(false);

const DeleteConfirmationPopup = () => {
  const [deleteContent, setDeleteContent] = useAtom(
    deleteConfirmationPopupAtom
  );
  const [mounted, setMounted] = useAtom(mountedAtom);
  const { locale } = useRouter();
  const deleteCommentHandler = async () => {
    try {
      await axios.delete(
        `${process.env.NEXT_PUBLIC_DIRECTUS_URL}/items/${deleteContent.collection}/${deleteContent.id}?access_token=${deleteContent.token}`
      );
      if (deleteContent.mutateFunction) {
        deleteContent.mutateFunction();
      } else {
        mutate(deleteContent.mutateId);
      }
      setDeleteContent({
        show: false,
        collection: "",
        id: "",
        token: "",
        mutateId: "",
      });
    } catch (error) {
      if (error.response.data?.errors[0].message.includes("violates")) {
        toast.error(
          getTranslatedText(
            locale,
            "Cevabı olan bir yorumu silemezsiniz.",
            "toast.deleteComment"
          )
        );
        setDeleteContent({
          show: false,
          collection: "",
          id: "",
          token: "",
          mutateId: "",
        });
      } else {
        errorToast(locale);
      }
      console.log(error.response);
    }
  };

  useEffect(() => {
    setMounted(true);
  }, [setMounted]);

  if (deleteContent.show && mounted) {
    return ReactDOM.createPortal(
      <div className="fixed z-50 flex items-center justify-center min-w-full min-h-screen px-5 bg-neutral-900 bg-opacity-80">
        <div className="bg-neutral-50 rounded-md min-w-[300px] ">
          <div className="px-8 py-6">
            <div className="text-red-600">
              <CircleXFill size={32} />
            </div>
            <h3 className="max-w-sm mt-6 text-lg font-medium">
              {getTranslatedText(
                locale,
                "Silme İşlemini Onayla",
                "deleteConfirmationPopup.title"
              )}
            </h3>
            <p className="max-w-sm mt-1">
              {getTranslatedText(
                locale,
                "Bu işlemi gerçekleştirmek istediğine emin misin?",
                "deleteConfirmationPopup.text"
              )}
            </p>
            <div className="flex items-center gap-6 mt-6">
              <PrimaryBlackButton handler={deleteCommentHandler} type="button">
                {getTranslatedText(
                  locale,
                  "Onaylıyorum",
                  "deleteConfirmationPopup.deleteButton"
                )}
              </PrimaryBlackButton>

              <TextButton
                handler={() => {
                  setDeleteContent({
                    show: false,
                    collection: "",
                    id: "",
                    token: "",
                  });
                }}
                type="button"
              >
                {getTranslatedText(
                  locale,
                  "Kapat",
                  "deleteConfirmationPopup.closeButton"
                )}
              </TextButton>
            </div>
          </div>
          <div className="px-8 py-4 text-xs border-t border-t-gray-300">
            {getTranslatedText(
              locale,
              "Silinen içeriğin geri getirilmesi mümkün değildir.",
              "deleteConfirmationPopup.infoText"
            )}
          </div>
        </div>
      </div>,
      document.getElementById("full-modal-root")
    );
  } else {
    return null;
  }
};

export default DeleteConfirmationPopup;
