import getTranslation from "../libs/getTranslations";

const getTranslatedText = (locale, defaultText, key) => {
  if (locale === "tr") {
    return defaultText;
  } else if (locale && locale != "tr") {
    const t = getTranslation(locale);
    const keys = key.split(".");
    if (keys.length === 2) {
      return t[keys[0]][keys[1]];
    } else if (keys.length === 3) {
      return t[keys[0]][keys[1]][keys[2]];
    } else if (keys.length === 4) {
      return t[keys[0]][keys[1]][keys[2]][keys[3]];
    } else if (keys.length === 5) {
      return t[keys[0]][keys[1]][keys[2]][keys[3]][keys[4]];
    } else if (keys.length === 6) {
      return t[keys[0]][keys[1]][keys[2]][keys[3]][keys[4]][keys[5]];
    } else {
      return defaultText;
    }
  } else {
    return defaultText;
  }
};

export default getTranslatedText;
